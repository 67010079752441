<template>
  <div>
    <x-table
      title="第三方平台"
      :card="true"
      tableType="list"
      :options="options"
      :hideSearchBar="false"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowDisable="disable"
      @rowEnable="enable"
      @beforeShow="beforeShow">
      <template slot="guide-content" slot-scope="{data}">
        <div class="x-text-cut">{{ data }} </div>
      </template>
      <template slot="status" slot-scope="scope">
        <b-badge light variant="danger" v-if="scope.row.status.value === 0">{{scope.row.status.text}}</b-badge>
        <b-badge light variant="primary" v-if="scope.row.status.value === 1">{{scope.row.status.text}}</b-badge>
      </template>
      <template slot="row-empower" slot-scope="scope">
        <div @click="searchSettings(scope.row.item)" >
          <feather-icon icon="AlignCenterIcon"  ></feather-icon>
          <span class="align-middle ml-50">赋权</span>
        </div>
      </template>
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="赋权"
      backdrop
      right
      shadow
      :visible="visible"
      width="820px"
      @hidden="hidden"
    >
      <empower-form
        v-if="visible"
        :open="open"
        @hiddenSuccess="hiddenSuccess"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, add, edit, remove,
} from '@/api/open/open'
import {
  BBadge, BSidebar, VBToggle,
} from 'bootstrap-vue'
import empowerForm from './empowerForm.vue'

export default {
  components: {
    XTable,
    BBadge,
    BSidebar,
    empowerForm,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      visible: false,
      open: {},
      enabledOath: false,
      content: [{
        prop: 1,
        title: '第一步',
        content: '啊哈大口袋里',
      }, {
        prop: 2,
        title: '第二部',
        content: '打卡来看待',
      }],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'empower', name: '赋权', icon: 'AlignCenterIcon' },
          { action: 'enable', name: '启用', icon: 'PlayIcon' },
          { action: 'disable', name: '禁用', icon: 'PowerIcon' }],
        actionFilter: (action, row) => {
          if (row.status.value === 1) {
            return action === 'view' || action === 'edit' || action === 'empower' || action === 'disable'
          }
          return action === 'view' || action === 'edit' || action === 'delete' || action === 'empower' || action === 'enable'
        },
        columns: [{
          label: '名称',
          labelShow: true,
          prop: 'clientName',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入第三方平台名称',
          },
        }, {
          label: 'clientId',
          labelShow: true,
          prop: 'id',
          searchShow: true,
          editDisable: true,
          addDisable: true,
          rules: {
            rule: 'required',
            message: '请输入clintId',
          },
        }, {
          label: 'clientSecret',
          labelShow: true,
          prop: 'secureKey',
          editDisable: true,
          rowShow: false,
          rules: {
            rule: 'required',
            message: '请输入clientSecret',
          },
        }, {
          label: '用户名',
          labelShow: true,
          prop: 'username',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入用户名',
          },
        }, {
          label: '密码',
          labelShow: true,
          editDisable: true,
          prop: 'password',
          rowShow: false,
          viewShow: false,
          editShow: false,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入密码',
          },
        }, {
          label: '开启OAuth2',
          labelShow: true,
          prop: 'enableOAuth2',
          rowShow: false,
          type: 'switch',
          onSwitchChange: (option, columns) => {
            if (option) {
              columns[6].addShow = true
            } else {
              columns[6].addShow = false
            }
          },
          searchShow: true,
        }, {
          label: 'redirectUrl',
          labelShow: true,
          prop: 'redirectUrl',
          tip: '认证成功以后的跳转地址',
          addShow: false,
          rowShow: false,
          searchShow: true,
        }, {
          label: 'IP白名单',
          labelShow: true,
          type: 'textarea',
          rowShow: false,
          prop: 'ipWhiteList',
          searchShow: true,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'status',
          viewShow: false,
          addShow: false,
          editShow: false,
          searchShow: true,
          rowSelect: true,
        }, {
          label: '描述',
          labelShow: true,
          type: 'textarea',
          rowShow: false,
          prop: 'description',
          searchShow: true,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchSettings(row) {
      this.open = this._.cloneDeep(row)
      this.visible = true
    },
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowDisable(data, done) {
      console.log('rowDisable')
      console.log(data)
      console.log(done)
    },
    addHandle(formData, done) {
      formData.status = 1
      add(formData).then(() => {
        done()
      })
    },
    getRandom(length) {
      const tempLength = length
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < tempLength; i += 1) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    },
    beforeShow(type, data) {
      data.id = this.getRandom(16)
      data.secureKey = this.getRandom(32)
    },
    // 启用
    enable(data, done) {
      data.status = 1
      edit(data).then(() => {
        this.$xtoast.success('启用成功！')
        done()
      })
    },
    // 禁用
    disable(data, done) {
      data.status = 0
      edit(data).then(() => {
        this.$xtoast.success('禁用成功！')
        done()
      })
    },
    hidden() {
      this.visible = false
    },
    hiddenSuccess(done) {
      this.visible = false
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
