import request from '@/auth/jwt/useJwt'

/*  查询第三方平台列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  let requestUrl = `open-api/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params !== null) {
    let index = 0
    if (params.clientName !== undefined && params.clientName !== '') {
      requestUrl += `&terms[${index}].column=clientName&terms[${index}].value=${params.clientName}`
      index++
    }
    if (params.id !== undefined && params.id !== '') {
      requestUrl += `&terms[${index}].column=id&terms[${index}].value=${params.id}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 新增平台
export function add(data) {
  return request.axiosIns({
    url: 'open-api',
    method: 'patch',
    data,
  })
}
// 新增平台
export function edit(data) {
  return request.axiosIns({
    url: 'open-api',
    method: 'patch',
    data,
  })
}
// 新增平台
export function remove(id) {
  return request.axiosIns({
    url: `open-api/${id}`,
    method: 'DELETE',
  })
}
// 无分页查询平台已授权的权限
export function queryAutzSetting(id) {
  return request.axiosIns({
    url: `autz-setting/_query/no-paging?paging=false&terms%5B0%5D.column=dimensionTarget&terms%5B0%5D.value=${id}`,
    method: 'GET',
  })
}
// 平台授权
export function autzSetting(data) {
  return request.axiosIns({
    url: 'autz-setting/detail/_save',
    method: 'post',
    data,
  })
}
// 无分页查询所有权限
export function permissionQuery() {
  return request.axiosIns({
    url: 'permission/_query/no-paging?paging=false',
    method: 'GET',
  })
}
