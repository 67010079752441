<template>
  <div>
      <b-row>
        <b-col cols="12">
          <div class="pl-2 pr-2 mt-4">
            <!-- Table Top -->
            <el-tree
              ref="tree"
              :data="settingList"
              default-expand-all
              empty-text="暂无数据"
              show-checkbox
              node-key="id"
              :default-expanded-keys="expandedKeys"
              :default-checked-keys="checkedKeys"
              :props="defaultProps">
            </el-tree>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex mt-3">
        <b-button
          class="flex-grow-1 ml-4 mr-4 mb-4"
          variant="primary"
          @click="settings">
          确定
        </b-button>
      </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  permissionQuery, queryAutzSetting, autzSetting,
} from '@/api/open/open'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    open: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'actions',
        label: 'name',
      },
      expandedKeys: [],
      checkedKeys: [],
      settingList: [],
    }
  },
  watch: {
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      this.settingList = []
      if (this.open.id !== undefined) {
        permissionQuery().then(resp => {
          let premissisonList = []
          resp.data.result.forEach(mission => {
            if (mission.properties && mission.properties.type && mission.properties.type.includes('api')) {
              mission.actions.forEach(action => {
                action.id = mission.id + '-' + action.action
                action.parentId = mission.id
              })
              premissisonList.push(mission)
            }
            this.settingList = premissisonList
          })
          let expanded = []
          let checked = []
          queryAutzSetting(this.open.id).then(res => {
            res.data.result.forEach(item => {
              expanded.push(item.permission)
              item.actions.forEach(action => {
                checked.push(item.permission + '-' + action)
              })
            })
            this.expandedKeys = expanded
            this.checkedKeys = checked
          })
        })
      }
    },
    settings() {
      const nodeList = this.$refs.tree.getCheckedNodes()
      let ids = []
      nodeList.forEach(item => {
        if (item.parentId && !ids.includes(item.parentId)) {
          ids.push(item.parentId)
        }
      })
      let settingList = []
      ids.forEach(id => {
        let setting = {
          id,
          actions: [],
        }
        nodeList.forEach(node => {
          if (node.parentId && node.parentId === id) {
            setting.actions.push(node.action)
          }
        })
        settingList.push(setting)
      })
      const settings = {
        permissionList: settingList,
        targetId: this.open.id,
        targetType: 'open-api',
      }
      autzSetting(settings).then(() => {
        this.$emit('hiddenSuccess', () => this.toast())
      })
    },
    toast() {
      this.$xtoast.success('设置成功！')
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
